/* eslint-disable functional/prefer-readonly-type */
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, Subject, Subscription, switchMap, take } from 'rxjs';
import { ComplexRating, Rating } from 'src/app/shared/shared.definitions';
import Swal from 'sweetalert2';
import { validate } from 'uuid';
import { ApiService } from '../../../shared/services/api.service';
import { FormService } from '../form.service';
import { AllowedFileData } from '../inputs/file-uploader/file-uploader.component';
import { Field, FormComponent } from '../interfaces/form.interfaces';
import { Option } from '../interfaces/option-interface';
import {
  arrayUniqueValidator,
  customDateRangeValidator,
  listingScreenValuesNotEmptyValidator,
  maximumChoicesValidator,
  minimumChoicesValidator,
  objectValuesRequiredValidator,
  serverErrorValidator,
  validatePattern,
} from '../validators/custom-validators';
import { TranslateService } from '@ngx-translate/core';

interface Page {
  id: string;
  components: FormComponent[];
  visibilityConstraints: VisibilityConstraint[];
}

export interface VisibilityConstraint {
  type: ConstraintType;
  value: string;
  sourceUuid: string;
}

export enum ConstraintType {
  EQ = 'eq',
  NEQ = 'neq',
  LT = 'lt',
  LTE = 'lte',
  GT = 'gt',
  GTE = 'gte',
  EMPTY = 'empty',
}
interface FormData {
  id: string;
  fillId?: string;
  pages: Page[];
}
interface SelectValues {
  extraChoices: Choice[];
  choices: Choice[];
}

interface PhoneNumber {
  countryCode: string;
  phoneNumber: string;
}

interface DateInterval {
  beginDate: string;
  endDate: string;
}

interface Choice {
  id?: string;
  text?: string;
}

type ValueTypes = Choice | DateInterval | Rating[] | ComplexRating[] | PhoneNumber | SelectValues | string;

interface ErrorData {
  invalidValue: string | null;
  message: string;
  propertyPath: string;
}

interface QueryParams {
  formId?: string;
  expires?: string;
  signature?: string;
  submitSignature?: string;
  previewHash?: string;
}

@Component({
  selector: 'app-form-stepper',
  templateUrl: './form-stepper.component.html',
  styleUrls: ['./form-stepper.component.scss'],
})
export class FormStepperComponent implements AfterViewInit, OnDestroy {
  formData: FormData;
  form = new UntypedFormGroup({});
  formBuildDone = false;
  staticTypes = ['heading', 'wysiwyg', 'spacer'];
  formBuildSubject$ = new Subject();
  pages = [];
  previousPage = 0;
  selectedPage = 0;
  pagesVisibility: boolean[] = [];
  visiblePagesCount = 0;
  examinablePageIndex = 0;
  id: string;
  fillId: string | null = null;
  header: HTMLElement | null;
  faultyFormPages: string[] = [];
  subs: Subscription[] = [];
  faultyFormPagesError = '';
  queryParams: QueryParams;

  constructor(
    private readonly actr: ActivatedRoute,
    private readonly formService: FormService,
    private readonly apiService: ApiService,
    private readonly sanitizer: DomSanitizer,
    private readonly route: Router,
    private readonly translate: TranslateService
  ) {
    this.id = this.actr.snapshot.data['data'].data.body.filter((c: any) => c.type === 'Body.Form.Form')[0].formId;
    this.queryParams = this.actr.snapshot.queryParams;
  }

  ngAfterViewInit(): void {
    const formId = this.queryParams.formId ? this.queryParams.formId : this.id;
    this.getFormData(formId, this.queryParams?.expires, this.queryParams?.signature, this.queryParams?.previewHash);
    this.header = document.getElementById('header-top');
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  getFormData(id: string, expires?: string, signature?: string, previewHash?: string): void {
    const queryParams = { expires, signature, previewHash };
    const getFormInfos$ = this.apiService.getFormInfos$(id, queryParams);

    const formValueChanges$ = this.form.valueChanges.pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
    );

    this.subs.push(
      getFormInfos$.pipe(
        take(1),
        switchMap((res) => {
          this.formData = res.data;
          this.fillId = this.formData?.fillId || null;
          this.formData.pages.forEach((page, index) => {
            const fg = `formPage${index}`;
            this.form.addControl(fg, new UntypedFormGroup({}));
            for (let cIndex = 0; cIndex < page.components.length; cIndex++) {
              if (!this.staticTypes.includes(page.components[cIndex].type)) {
                const formGroup = this.form.get(fg) as UntypedFormGroup;
                const validators = this.createValidatorArray(page.components[cIndex]);
                const value = this.mapValueBackToFormComponent(page.components[cIndex].value as string, page.components[cIndex].type);
                formGroup.addControl(page.components[cIndex].id, new UntypedFormControl(value, validators));
              }
            }
          });
          this.formBuildDone = true;
          this.getVisiblePagesNumber();

          if (this.fillId) {
            if (!this.queryParams.submitSignature && this.queryParams.signature) {
              this.form.disable();
            }
            this.formBuildSubject$.next(true);
          }

          return formValueChanges$;
        })
      ).subscribe(() => {
        this.getVisiblePagesNumber();
        this.findInvalidControls
      })
    );
  }

  mapValueBackToFormComponent(value: ValueTypes, type: string): ValueTypes | null {
    if (value === null) {
      switch (type) {
        case 'simple_input':
          return '';
        default:
          return null;
      }
    }
    let valueToReturn;
    switch (type) {
      case 'dropdown_select': {
        value = value as SelectValues;
        const choices = value.choices.map((e) => {
          return { id: e };
        });
        const extraChoices = value.extraChoices.map((e, index) => {
          return { text: e, id: index };
        });
        valueToReturn = [...choices, ...extraChoices];
        break;
      }
      case 'checkbox_group':
        value = value as SelectValues;
        valueToReturn = value.choices;
        break;
      case 'rating' || 'questionairre': {
        valueToReturn = [];
        Object.keys(value).forEach((k) => {
          valueToReturn.push({ questionId: k, answerId: value[k as keyof ValueTypes] });
        });
        break;
      }
      case 'ranking': {
        value = value as Rating[];
        valueToReturn = [];
        value.forEach((v, index) => {
          if (v.id === null) {
            v.id = index.toString();
            v.text = v.value;
            delete v.value;
          }
          v.numberOf = index + 1;
          valueToReturn.push(v);
        });
        break;
      }
      case 'date_interval_picker': {
        value = value as DateInterval;
        valueToReturn = `${value.beginDate}, ${value.endDate}`;
        break;
      }
      case 'phone_number':
        value = value as PhoneNumber;
        valueToReturn = `${value.countryCode} ${value.phoneNumber}`;
        break;
      case 'complex_ranking': {
        value = value as ComplexRating[];
        valueToReturn = [];
        value.forEach((v, index) => {
          if (!v?.text && v.value) {
            v.text = v.value;
            delete v.value;
          }
          if (v.id === null) {
            v.id = index.toString();
            if (v?.value) {
              delete v.value;
            }
          }
          v.numberOf = index + 1;
          valueToReturn.push(v);
        });
        break;
      }

      default:
        valueToReturn = value;
    }
    return valueToReturn;
  }
  createValidatorArray(component: FormComponent): ValidatorFn[] {
    const validators = [];
    if (component?.type === 'ranking' || component?.type === 'complex_ranking') {
      validators.push(arrayUniqueValidator());
    }
    if (component?.type === 'complex_ranking') {
      validators.push(listingScreenValuesNotEmptyValidator(!!component.screen2Required, !!component.screen3Required));
    }
    if (component?.required) {
      if (component.type === 'quantity') {
        validators.push(objectValuesRequiredValidator());
      } else if (component.type === 'document_agree') {
        validators.push(Validators.requiredTrue);
      } else {
        validators.push(Validators.required);
      }
    }
    if (component?.beginDateRequired || component?.endDateRequired) {
      if (component.type === 'date_interval_picker') {
        validators.push(customDateRangeValidator(this.translate));
      }
    }
    if (component?.regex && component.type !== 'email') {
      validators.push(validatePattern(component.regex));
    }
    if (component?.maximumCharacterLimit) {
      validators.push(Validators.maxLength(component.maximumCharacterLimit));
    }
    if (component?.minimumCharacterLimit) {
      validators.push(Validators.minLength(component.minimumCharacterLimit));
    }
    if (component.type === 'email') {
      validators.push(Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/));
    }
    if (component.type === null) {
      if (component?.columns?.filter((column) => column.required).length) {
        validators.push(Validators.required);
      }
    }
    if (component?.minimumChoiceLimit) {
      validators.push(minimumChoicesValidator(component.minimumChoiceLimit));
    }
    if (component?.maximumChoiceLimit) {
      validators.push(maximumChoicesValidator(component.maximumChoiceLimit));
    }
    return validators;
  }
  getFormControl(id: string, pageIndex?: number): UntypedFormControl {
    return this.form.get(`formPage${pageIndex ? pageIndex : this.selectedPage}`)?.get(id) as UntypedFormControl;
  }

  sanitizedContent(content: string | undefined): SafeHtml | null {
    if (content) {
      return this.sanitizer.bypassSecurityTrustHtml(content);
    }

    return null;
  }

  returnAsOptionArray(data: Option[] | AllowedFileData[] | undefined): Option[] {
    return data as Option[];
  }

  returnAsAllowedFileDataArray(data: Option[] | AllowedFileData[] | undefined): AllowedFileData[] {
    return data as AllowedFileData[];
  }

  next(): void {
    this.previousPage = this.selectedPage;
    this.selectedPage++;

    const currentPageForm = this.form.get(`formPage${this.previousPage}`) as FormGroup;

    Object.values(currentPageForm.controls).forEach((control) => {
      control.markAsTouched();
    });

    if (this.selectedPage === this.formData.pages.length - 1) {
      this.findInvalidControls();
    }

    this.header?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  findInvalidControls(): void {
    this.faultyFormPages = [];
    if (this.formData.pages.length === 1) {
      return;
    }
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.faultyFormPages.push(parseInt(name.replace('formPage', '')) + 1 + '.');
      }
    }
    const faultyPages = this.faultyFormPages.join(' ');
    this.faultyFormPagesError = this.translate.instant('formErrors.pageContainsErrors', { faultyPages });
  }

  getIsNextDisabled(): boolean {
    return (
      this.formData.pages[this.selectedPage].components.some((component) => component.type === 'complex_ranking') &&
      this.form.controls[Object.keys(this.form.controls)[this.selectedPage]].invalid
    );
  }

  prev(): void {
    this.previousPage = this.selectedPage;
    this.selectedPage--;

    this.header?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  createFormObjectAccordingToFormType(component: FormComponent, controlValue: any, formValueArray: Field[]): void {
    let formItem;
    switch (component.type) {
      case 'ranking':
        controlValue?.sort((a: any, b: any) => +a.numberOf - +b.numberOf);
        formItem = {
          id: component.id,
          value: controlValue?.map((cValue: any) => {
            return {
              id: cValue.id.length > 3 ? cValue.id : null,
              value: cValue.text,
            };
          }),
        };
        break;
      case 'complex_ranking':
        controlValue?.sort((a: any, b: any) => +a.numberOf - +b.numberOf);
        formItem = {
          id: component.id,
          value: controlValue?.map((cValue: any) => {
            return {
              id: cValue.id.length > 3 ? cValue.id : null,
              value: cValue?.text?.length > 0 ? cValue.text : null,
              screen2Values: cValue?.screen2Values?.length > 0 ? cValue.screen2Values : null,
              screen3Values: cValue?.screen3Values?.length > 0 ? cValue.screen3Values : null,
            };
          }),
        };
        break;

      case 'rating': {
        const value: any = {};
        controlValue?.forEach((cValue: any) => {
          value[cValue.questionId] = cValue.answerId;
        });
        formItem = {
          id: component.id,
          value: Object.keys(value).length > 0 ? value : null,
        };
        break;
      }
      case 'questionare':
        {
          const value: any = {};
          controlValue?.forEach((cValue: any) => {
            value[cValue.questionId] = cValue.answerId;
          });
          formItem = {
            id: component.id,
            value: controlValue?.length > 0 ? value : null,
          };
        }
        break;
      case 'checkbox_group':
        formItem = {
          id: component.id,
          value: controlValue?.length > 0 ? { choices: controlValue, extraChoices: [] } : null,
        };
        break;
      case 'date_interval_picker': {
        const dates = controlValue?.split(',');
        let dateObj = { beginDate: null, endDate: null };
        if (dates?.length === 2) {
          dateObj = { beginDate: dates[0], endDate: dates[1]?.trim() };
        }
        formItem = {
          id: component.id,
          value: dateObj,
        };
        break;
      }
      case 'phone_number': {
        const phoneNumbers = controlValue?.split(' ');
        const phoneObject = {
          countryCode: phoneNumbers?.length ? phoneNumbers[0] : null,
          phoneNumber: phoneNumbers?.length ? phoneNumbers[1] : null,
        };
        formItem = {
          id: component.id,
          value: phoneObject,
        };
        break;
      }
      case 'dropdown_select': {
        const choices: string[] = [];
        const extraChoices: string[] = [];
        if (Array.isArray(controlValue)) {
          controlValue.forEach((selectValues) => {
            if (selectValues.id.length > 2) {
              choices.push(selectValues.id);
            } else {
              extraChoices.push(selectValues.text);
            }
          });
        }
        formItem = {
          id: component.id,
          value: choices?.length > 0 ? { choices, extraChoices } : null,
        };
        break;
      }
      case null:
        formItem = {
          id: component.id,
          value: controlValue,
        };
        break;

      default:
        formItem = {
          id: component.id,
          value: controlValue,
        };
    }
    component.value = formItem.value;
    formValueArray.push(formItem);
  }

  findControlById(id: string): UntypedFormControl | null {
    const uuid = id.replace(/\[.*?\]/g, '');
    const groupNames = Object.keys(this.form.controls);

    for (let i = 0; i < groupNames.length; i++) {
      const groupName = groupNames[i];
      const group = this.form.get(groupName) as UntypedFormGroup;

      if (group.contains(uuid)) {
        return group.get(uuid) as UntypedFormControl;
      }
    }

    return null;
  }

  calculateNewPageIndex(errorData: ErrorData[]): number | null {
    for (let errIndex = 0; errIndex < errorData.length; errIndex++) {
      const propertyPath = errorData[errIndex].propertyPath;

      const uuid = propertyPath.replace(/\[.*?\]/g, '');
      const groupNames = Object.keys(this.form.controls);

      for (let index = 0; index < groupNames.length; index++) {
        const groupName = groupNames[index];
        const group = this.form.get(groupName) as UntypedFormGroup;

        if (group.contains(uuid)) {
          return index;
        }
      }
    }

    return null;
  }

  getVisiblePagesNumber(): void {
    this.pagesVisibility = [];
    this.formData?.pages?.forEach((page, index) => {
      this.examinablePageIndex = index;
      this.pagesVisibility.push(this.getPageVisibility(page));
    });
    this.visiblePagesCount = this.pagesVisibility.filter((page) => page).length;
  }

  getPageVisibility(page?: Page): boolean {
    const selectedPage = page ? page : this.formData?.pages[this.selectedPage];
    const pageConstraints = selectedPage?.visibilityConstraints;

    if (!pageConstraints || pageConstraints.length === 0) {
      return true;
    }

    const pageComponents = selectedPage?.components;
    const checkForPageCount = page ? true : false;

    return this.getVisibilityByConstraints(pageConstraints, pageComponents, checkForPageCount);
  }

  isFormFieldVisible(formComponent: FormComponent): boolean {
    const constraints = formComponent.visibilityConstraints;
    let isVisible = true;

    isVisible = this.getVisibilityByConstraints(constraints as VisibilityConstraint[], formComponent);

    return isVisible;
  }

  getVisibilityByConstraints(
    constraints: VisibilityConstraint[],
    formComponent: FormComponent | FormComponent[],
    checkForPageCount?: boolean
  ): boolean {
    if (!constraints || constraints.length === 0) {
      return true;
    }

    let isVisible = true;

    if (!checkForPageCount) {
      if (Array.isArray(formComponent) && formComponent.length > 0) {
        formComponent.forEach((fc) => this.updateValidators(fc));
      } else if (!Array.isArray(formComponent)) {
        this.updateValidators(formComponent as FormComponent);
      }
    }

    isVisible = constraints.some((constraint: VisibilityConstraint) => {
      const control = this.findControlById(constraint.sourceUuid);
      if (!control || (!control?.value && constraint.type !== ConstraintType.EMPTY)) {
        return false;
      }

      let controlValue = control.value;

      if (typeof controlValue === 'string') {
        const isUuid = validate(controlValue);
        if (isUuid) {
          const componentData = this.formData.pages[this.selectedPage].components.find((c) => c.id === constraint.sourceUuid);
          if (componentData?.type === 'radio_group') {
            controlValue = (componentData?.options as Option[])?.find((o) => o.id === control.value)?.text;
          }
        } else {
          controlValue = control.value.trim();
        }
      } else if (Array.isArray(controlValue)) {
        controlValue = controlValue.map((val) => {
          const isUuid = validate(val);
          if (isUuid) {
            const componentData = this.formData.pages[this.selectedPage].components.find((c) => c.id === constraint.sourceUuid);
            if (componentData?.type === 'checkbox_group') {
              return (componentData?.options as Option[])?.find((o) => o.id === val)?.text;
            }
          } else {
            return val.text;
          }
        });
      }

      let constraintValue: string | number = typeof constraint?.value === 'string' ? constraint.value.trim() : constraint.value;

      if (this.isDateString(controlValue) || this.isDateString(constraintValue)) {
        controlValue = new Date(controlValue).getTime();
        constraintValue = new Date(constraintValue).getTime();
      }

      switch (constraint.type) {
        case ConstraintType.EMPTY:
          return true;
        case ConstraintType.EQ:
          if (Array.isArray(controlValue)) {
            return controlValue.some((text) => text == constraintValue);
          } else {
            return controlValue == constraintValue;
          }
        case ConstraintType.NEQ:
          if (Array.isArray(controlValue)) {
            return controlValue.some((text) => text != constraintValue);
          } else {
            return controlValue != constraintValue;
          }
        case ConstraintType.LT:
          return controlValue < constraintValue;
        case ConstraintType.LTE:
          return controlValue <= constraintValue;
        case ConstraintType.GT:
          return controlValue > constraintValue;
        case ConstraintType.GTE:
          return controlValue >= constraintValue;
        default:
          return true;
      }
    });

    if (!isVisible) {
      if (Array.isArray(formComponent) && formComponent.length > 0) {
        formComponent.forEach((fc) => this.updateValidators(fc, true));
      } else if (!Array.isArray(formComponent)) {
        this.updateValidators(formComponent, true);
      }
    }

    return isVisible;
  }

  updateValidators(formComponent: FormComponent, isRemove = false): void {
    const currentControl = this.getFormControl(formComponent.id, this.examinablePageIndex);
    const validators: ValidatorFn[] = [];

    if (currentControl) {
      if (isRemove) {
        currentControl.reset();
        if (currentControl.validator) {
          currentControl.clearValidators();
        }
        currentControl.updateValueAndValidity();
      } else {
        this.createValidatorArray(formComponent).forEach((validator) => validators.push(validator));
        currentControl.setValidators(validators);
        currentControl.updateValueAndValidity();
      }
    }
  }

  isDateString(str: string): boolean {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;

    return datePattern.test(str);
  }

  submitForm(): void {
    this.formService.formSubmitted = true;
    if (this.form.invalid) return;
    const formValue = this.form.value;
    const fields: Field[] = [];
    this.formData.pages.forEach((page, index) => {
      page.components.forEach((c) => {
        const controlValue = formValue[`formPage${index}`][c.id];
        if (!this.staticTypes.includes(c.type) && this.pagesVisibility[index]) {
          this.createFormObjectAccordingToFormType(c, controlValue, fields);
        } else {
          fields.push({ id: c.id, value: null });
        }
      });
    });
    this.queryParams = this.actr.snapshot.queryParams;
    const formId = this.queryParams.formId ? this.queryParams.formId : this.id;
    this.apiService.postForm$(formId, fields, this.queryParams).subscribe(
      () => {
        Swal.fire({
          title: this.translate.instant('popup.successfulSubmission'),
          text: this.translate.instant('popup.redirectMessage'),
          icon: 'success',
          confirmButtonText: this.translate.instant('popup.okButton'),
        }).then((result) => {
          if (result.isConfirmed) {
            this.route.navigate(['', 'urlap-lista']);
          }
        });
      },
      (error) => {
        if (error.status === 422) {
          const errorData: ErrorData[] = error.error.data;
          if (Array.isArray(errorData) && errorData.length > 0) {
            const newPageIndex = this.calculateNewPageIndex(errorData);
            this.selectedPage = newPageIndex as number;

            errorData.forEach((validationError) => {
              const propertyPath = validationError.propertyPath;
              const errorMessage = validationError.message;

              const control = this.findControlById(propertyPath);

              if (control) {
                control.addValidators(serverErrorValidator(errorMessage));
                control.setErrors({ serverError: errorMessage });
                control.markAsDirty();
              }
            });
          }
        } else {
          Swal.fire({
            title: this.translate.instant('popup.unsuccessfulSubmission'),
            text: this.translate.instant('popup.submissionError'),
            icon: 'error',
            confirmButtonText: this.translate.instant('popup.okButton'),
          });
        }
      }
    );
  }
}
